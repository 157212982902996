.blogPostViewerContentGrid {
    max-width: 500px;
}

@media only screen and (max-width: 600px) {
    .blogPostViewerContentGrid {
        margin: 10px;
    }
}

.blogPostViewerPostContentDiv {
    word-wrap: break-word !important;
    display: inline-block;
    overflow:hidden;
}