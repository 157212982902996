.sessionListCardTitle {
    text-align: center;
}

.sessionListCardSubtitle {
    margin-top: 10px;
    text-transform: none;
    font-weight: 500;
    text-align: center;
}


.sessionListSessionList {
    margin-top: 20px !important;
}

.sessionListDataCollectionButton {
    text-transform: none;
    justify-content: center;
}

.sessionListButtonLabelParagraph {
    margin-top: 5px !important;
    font-size: xx-small !important;
    color: gray;
    text-align: center;
}

@media (prefers-color-scheme: dark) {
    .sessionListItem {
        --background: #1e1e1e;
    }
}


.sessionListGrid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;

    margin-top: 10px;
    margin-bottom: 5px;
}


.sessionListNotes {
    flex: 2;
    justify-content: center;
    cursor: pointer;
}