.childrenSwitchPopover {
    --width: 90%;
    --max-width: 700px;
    --backdrop-opacity: 0.8;
}

.childrenSwitchPopoverTitle {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.childrenSwitchChildListItem {
    margin-bottom: 10px;
}

.childrenSwitchChildAvatarImg {
    width: 10vw;
    height: 10vw;
    max-width: 50px;
    max-height: 50px;

    margin-bottom: 10px;
}