.childrenContentGrid {
    max-width: 700px;    
}

.childrenCardSubtitle {
    text-transform: none;
    font-weight: normal;
}

.childrenList {
    width: 100%;
}

.childrenListLoadingDiv {
    margin-top: 30px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
}