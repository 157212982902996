.magicLinkAccessGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.magicLinkAnimationPlayerRow {
    width: 90vw;
    max-width: 500px;
    height: auto;
}

.magicLinkWelcomeTextDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.magicLinkWelcomeTextParagraph {
    padding-left: 20px;
}