.playerPage {
    --ion-background-color: black;
}
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-align: center;
    vertical-align: center;
    /* --ion-background-color: cyan; */
}

.autonomyPlayerBackButton {
    color: white;
    z-index: 250;
    position: absolute;
    justify-content: center;
    top: 20px;
    left: 10px;
    --background: transparent;
    --box-shadow: none;
}

.autonomyPlayerBackButtonIcon {
    color: white !important;
}

.videoPlayer {
    flex: 1;
    height: calc(100vh - 5px);
    width: calc(100vw - 5px);
    z-index: 100;
}

.controls {
    z-index: 200;
    position: absolute;
    justify-content: center;
    bottom: 20px;
    left: 10px;
    right: 10px;
}

.controlButton {
    flex: 1;
}

.controlButtonDisabled {
    flex: 1;
    /* opacity: 0.3; */
    /* color: transparent; */
    /* --background: transparent; */
}

.taskPlayerImg {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}