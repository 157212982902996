.childDashboardContentGrid {
	max-width: 700px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.childDashboardLoadingChildDataDiv {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.childDashboardInvitationActionButtonsDiv {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.childDashboardCardContent {
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: left;
}

.childDashboardCardContentDiv {
  flex: 1;
}

.dashboardFieldLabel {
  align-self: stretch;
}

.dashboardAccessListCard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboardCardHeader {
  width: 100%;
  margin-bottom: 10px;
}

.childDashboardAccessListRowDiv {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	margin-bottom: 10px;
}

.childDashboardAccessListRemoveAccessDiv {
	margin: 5px;
}

.childDashboardAccessListEmailDiv {
	flex: 2;
}

.childDashboardAccessListRoleDiv {
	flex: 1;
}

.childDashboardInvitationsDiv {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.childDashboardInvitationsEmailDiv {
	flex: 5;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.childDashboardInvitationsSentAtDiv {
	flex: 2;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.childDashboardInvitationsBinDiv {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.childDashboardInvitationHeaderButton {
	visibility: hidden;
}

.childDashboardShareChildButton {
	text-transform: none;
}

.childDashboardShareChildButtonIcon {
	margin-left: 10px;
}

.childDashboardDeleteChildButton {
	text-transform: none;
}