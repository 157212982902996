.supportGrid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.moreGrid {
    max-width: 700px;
}

.userSettingsProfileInput {
    --color: black;
    --placeholder-color: gray;
}

.userSettingsProfileEmailParagraph {
    color: black;
    text-align: center;
    flex: 1;
    
    cursor: pointer;
}

@media (prefers-color-scheme: dark) {
    .userSettingsProfileInput {
        --color: white;
    }
    .userSettingsProfileEmailParagraph {
        color: white;
    }
}

.supportOptionRow {
    margin-top: 5px;
    margin-bottom: 5px;
}

.supportOptionIcon {
    flex: 1;
}

.supportOptionText {
    flex: 3;
    font-size: large;
}

.horizontal-scroll {
    overflow: auto;
    white-space: nowrap;
}
.avatar{
    width: 100px;
    height: 100px;
    margin: auto;
    
}

.avatarImg{ 
    background-color: white;
    border-radius: 50% !important;
    border: 3px solid var(--ion-color-light-shade) !important;
}

.avatarImgCurrent{ 
    background-color: white;
    border-radius: 50% !important;
    border: 3px solid var(--ion-color-primary) !important;
}

/* DEVICES MANAGEMENT */
.userSettingsDeviceDiv {
    display: flex;
    flex-direction: row;
}

.userSettingsNotificationDeviceOsDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userSettingsNotificationDeviceOsIcon {
    flex: 1;
    padding-right: 10px;
}

.userSettingsDeviceActiveToggleDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.userSettingsDeviceActiveToggleLabel {
    margin-top: 5px !important;

    font-size: xx-small !important;
    color: gray;
    text-align: center;
}

.moreFeedbackCardContent {
    display: flex;
    justify-content: center;
}

.moreFeedbackAndSupportButton {
    min-height: 60px;
    text-transform: none;
}

.logoutButtonRow {
    flex: 1;
    justify-content: center;
    align-items: center;
}

.licensesButtonRow {
    margin-top: 10px;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.userSettingsBetaTesterRow {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.userSettingsBetaTestingRequestedParagraph {
    color: gray;
    text-align: center;
}

.userSettingsBetaTesterButton {
    --background: gold;
    color: black;
}

.userSettingsLogoutButton {
    text-align: center;
    text-transform: none;
}

.userSettingsAppVersionNumberParagraph {
    text-align: center;
    color: gray;
    font-size: small;
}