.blogHomeContentGrid {
    max-width: 500px;
}

.blogHomeChipDiv {
    margin-left: 10px;
    margin-right: 10px;
}

@media (prefers-color-scheme: light) {
    .blogHomeCategoryChip {
        background-color: lightblue;
        font-size: large;
    }
    
    .blogHomeCategorySelectedChip {
        background-color: #3880ff;
        color: white;
        font-size: large;
    }
}

@media (prefers-color-scheme: dark) {
    .blogHomeCategoryChip {
        background-color: lightblue;
        color: black;
        font-size: large;    
    }
    
    .blogHomeCategorySelectedChip {
        background-color: #428cff;
        color: white;
        font-size: large;
    }
}