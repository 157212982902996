.emailSentMainGrid {
    max-width: 500px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.emailSentTextDiv {
    padding-left: 20px;
    padding-right: 20px;
    
    text-align: center;
}