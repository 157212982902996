.autonomyEditorGrid{
    max-width: 700px;
}

.autonomyTitleInput {
    text-align: center;
    font-size: larger;
    font-weight: bold;
}

.taskEditorTaskTitleLoadingDiv {
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    bottom: 0;
}

.taskEditorCoverItem {
    display: flex;
    flex-direction: row;
    
    /* justify-content: center; */
    align-items: center;

    margin-top: 10px;
    margin-bottom: 10px;
}

.taskEditorCoverImg {
    height: 10vh;
    width: 10vh;
    max-height: 60px;
    max-width: 60px;

    margin-left: 0;
}

.taskEditorStepsTitle {
    margin-top: 20px;
}

.taskEditorStepItem {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: row;

    padding-bottom: 10px;
    padding-top: 10px;

    margin-left: 10px;
    margin-right: 10px;    

    border-bottom: 1px solid lightgray;
}


.taskEditorStepTextarea {
    flex: 4;

    text-align: start;
    vertical-align: middle;

    padding-left: 10px;

    --color: black;
}

.taskEditorAddMediaButton{
    text-transform: none;
}

.taskEditorMediaPreview{
    width: 120px;
}
.taskEditorVideo{
    max-height: 150px;
    width: 20vw;
}

.taskEditorPopover{
    --width:50vw;
    --height:70vh
}
.taskEditorPopoverTitle{
    text-align: center;
}
.taskEditorPopoverMainGrid {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.taskEditorPopoverRow {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.taskEditorPopoverButton {
    text-transform: none;
}

.taskEditorPopoverImage {
    margin: 10%;
    max-width: 50vw;
    max-height: 30vh;
    object-fit: scale-down;
    align-self: center;
}
.taskEditorPopoverVideo {
    margin: 10%;
    max-width: 50vw;
    max-height: 30vh;
    object-fit: scale-down;
    align-self: center;
}

@media only screen and (max-width: 600px) {
    .taskEditorPopover{
        --width:80vw;
        --height:50vh
    }
    .taskEditorPopoverVideo{
        max-width: 60vw;
    }
    .taskEditorPopoverImage{
        max-width: 60vw;
    }
    .taskEditorAddMediaButton{
        width: 30vw;
        font-size: 13px;
    }
}


@media (prefers-color-scheme: dark) {
    .taskEditorStepTextarea {
        --color: white;
    }
}

.taskEditorPreviewImage {
    height: calc(100vh / 5);
    width: auto;
    
    flex: 1;

    cursor: pointer;

    object-fit: contain;
}
.taskEditorAddVideoIcon {
    margin-right: 0;
    margin-left: auto;
}

.taskEditorButtonsGrid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.taskEditorAddStepButton {
    margin-top: 20px;
    margin-bottom: 20px;
}

.taskEditorAddCoverCard {
    margin-top: 50px;
}

.taskEditorUploadInput {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px !important;
    cursor: pointer;
    opacity: 0;
    /* filter: alpha(opacity = 0); */
}

.taskEditorCardTitle {
    margin-top: 20px;
    margin-bottom: 0;
    margin-left: 20px;

    font-size: large;
    font-weight: 500;
    color: gray;
}

.taskEditorCardSubtitle {
    margin-top: 20px;
    margin-bottom: 0;
    margin-left: 20px;

    font-size: small;
    font-weight: 400;
    color: gray;
}

.taskEditorDataCollectionModeSelector {
    color: black !important;
    font-size: medium;
}
@media (prefers-color-scheme: dark) {
    .taskEditorDataCollectionModeSelector {
        color: white !important;
    }
    .taskEditorCardTitle{
        color: var(--ion-color-secondary);
    }
}

.taskEditorStepsList {
    margin-top: 15px;
}

.taskEditorTaskStepsLoadingDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.deleteAutonomyRow {
    margin-top: 15px;
}

.taskEditPlaceholderImageButton{
    font-size: 15px;
    font-weight: normal;
    text-transform: none;
    height: 40px;
}

/* ADD STEP POPOVER CLASSES */
.autonomyEditorPopover{
    --width: 90%;
    --max-width: 700px;
    --backdrop-opacity: 0.8;
}

.autonomyEditorPopoverDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 20px;
}

.autonomyEditorPopoverTitle{
    font-size: x-large;
}
@media only screen and (max-width: 600px) {
    .autonomyEditorPopoverTitle{
        font-size: 20px;
    }
}

.taskEditorPopoverAddVideoDiv {
    color: gray;
}

.taskEditorPopoverAddVideoHintParagraph {
    font-size: small;
    margin: 0;
    margin-bottom: 10px;
}

.taskEditorPopoverAddVideoHelpButtonIos {
    position: absolute;
    top: 0;
    right: 0;
}
/* END OF ADD STEP POPOVER CLASSES */

.taskEditorAddCoverHelpPopover {
    width: 200px;
    height: 200px;
}


.taskEditorAddCoverHelpParagraph {
    margin: 20px;
}

.taskEditorAddCoverHelpButton {
    cursor: help !important;
}


/* SHAKE ANIMATION FOR REORDERING */  
  @keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
      }
      
      20%, 80% {
        transform: translate3d(2px, 0, 0);
      }
    
      30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
      }
    
      40%, 60% {
        transform: translate3d(4px, 0, 0);
      } 
  }