.taskChartDocumentReadyGrid {
    max-width: 500px;
}

.taskChartDocumentReadyDiv {
    margin-top: 20vh;
    text-align: center;

    cursor: pointer;
}

.taskChartDocumentReadyButton {
    text-transform: none;
    --padding-bottom: 30px;
    --padding-end: 30px;
    --padding-start: 30px;
    --padding-top:  30px;
}

.apexcharts-text tspan{
    background-color: white;

}
.chartChart{
    background-color: white;
    height: 85vh !important;
}

.chartModeFilterButtons{
    display: flex;
    justify-content: center;
}

.chartDiv{
    height: auto;
    width: 100%;
}

.chartExportButton{
    text-transform: none;  
}

.chartSessionSelectionDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}

.apexcharts-toolbar {
    position: absolute;
    right: auto;
    left: 10px;
}
.apexcharts-toolbar {
    position: absolute;
    right: auto;
    left: 10px;
}
.apexcharts-toolbar-custom-icon{
    width: 50px !important;
    flex-shrink: 0;
    height: 30px;
}
.chartNoSessionsButton{
    width: fit-content;
    align-self: center;
}