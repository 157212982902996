.pbReportPopover {
    --width: 90%;
    margin-top: -50px;
    --max-width: 700px;
    --backdrop-opacity: 0.8;
}

.pbReportMainDiv {
    margin: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
/*     background-color: red !important; */
}

.pbReportPopoverTitle {
    margin-bottom: 20px;
    font-size: x-large; 
}

.pbReportPopoverSubtitle {
    font-size: medium;
    color: gray;
}