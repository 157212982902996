.resetPasswordMainGrid {
    max-width: 500px;

    height: 80vh;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.resetPasswordInputDiv {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    padding-right: 20px;
    padding-left: 20px;
}

.resetPasswordInputInnerDiv {
    margin-bottom: 20px;
}

.resetPasswordButtonDiv {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding-right: 20px;
    padding-left: 20px;
}

.resetPasswordInputField {
    margin-top: 20px;
    margin-bottom: 5px;
    --padding-start: 15px;
    --padding-end: 15px;

    border: 1px solid gray;
    border-radius: 20px;
    overflow: hidden;
}

.resetPasswordButton {
    text-transform: none;
}