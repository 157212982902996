.loginMainGrid {
    max-width: 500px;

    display: flex;
    flex-direction: column;
    height: 80vh;
}

.loginInputsDiv {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.loginInputsInnerDiv {
    margin-bottom: 30px;

    padding-right: 20px;
    padding-left: 20px;
}

.loginInputField {
    margin-top: 20px;
    margin-bottom: 5px;
    --padding-start: 15px;
    --padding-end: 15px;

    border: 1px solid gray;
    border-radius: 20px;
    overflow: hidden;
}

.loginButtonsDiv {
    flex: 1;

    display: flex;
    flex-direction: column;

    padding-right: 20px;
    padding-left: 20px;
}

.loginLoginButton {
    text-transform: none;
}

.loginResetPasswordButton {
    text-transform: none;
}