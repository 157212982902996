.pbListGrid{
    max-width: 500px;
}

.pbListCreateNewPbHint {
    color: gray;
}

.pbListButtons{
    display: flex;
    justify-content: center;
}