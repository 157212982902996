.invitationPage{
    --background: #A7CDF2 !important;
}
.invitationMainGrid {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}
.invitationTitleDiv{
    margin-top: 2%;
    text-align: center;
}
.invitationTitle{
    font-size: 40px;
    font-weight: 500;
    color: rgb(236, 236, 236);
}
.invitationCard{
    border-radius: 15px;
    width: 600px;
    height: 450px;
    box-shadow: 5px 5px 10px 5px #8babca;
}
.invitationCardContent{
    display: flex;
    flex-direction: column;
}
.invitationUserName{
    font-size: 20px;
    text-align: center;
    font-weight: 600;
}
.invitationUserRole{
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
}
.invitationLottie{
    width: 50%;
}
.invitationButtons{
    justify-content: center;
    flex-direction: row-reverse;
    margin-top: 15px;
}
.invitationAcceptButton{
    border-radius: 10px;
    width: 100px;
    height: 40px !important;
    font-size: 18px !important;
    background-color: #008d2a;
    color: whitesmoke;
    text-transform: none;
    margin-left: 20px !important;
}
.invitationDeclineButton{
    border-radius: 10px;
    border: 1px solid black;
    width: 100px;
    height: 40px !important;
    font-size: 18px !important;
    color: #353B6E;
    text-transform: none;
    margin-right: 20px !important;
}
.invitationMissingContinueButton{
    border-radius: 10px;
    border: 1px solid black;
    width: 200px;
    height: 40px !important;
    font-size: 18px !important;
    color: #242424;
    text-transform: none;
}

@media only screen and (max-width: 480px) {
    .invitationCard {
        width: 80%;
    }
}