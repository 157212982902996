.manageProductsCardContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.manageProductsProductDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 10px;

    border-bottom: 1px solid gray;
}

.manageProductsProductName {
    font-weight: bold;
    color: black;
}

@media (prefers-color-scheme: dark) {
    .manageProductsProductName {
        color: white;
    }
}

.manageProductsManageProductButton {
    text-transform: none;
}

.manageProductsFromMobileOnWebParagraph {
    text-align: center;
}

.manageProductsPurchaseMaestroOnWebButton {
    --background: orange;
    text-transform: none;

    margin-top: 20px;
    margin-bottom: 10px;
}