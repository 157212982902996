.dataCollectionHomeContentGrid {
    max-width: 700px;
}

.dataAnalysisHomeCard {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
    overflow: hidden;

    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media (prefers-color-scheme: dark) {
    .dataAnalysisHomeCard {
        background-color: var(--ion-card-background);
        box-shadow: none;
    }
}

.dataAnalysisHomeCardTitle {
    font-size: x-large;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
}

.dataAnalysisHomeCardContentDiv {
    padding-left: 20px;
    padding-right: 20px;
}

.dataAnalysisHomeLoadingDiv {
    margin-top: 50px;
    padding: 10px;
}

.dataAnalysisHomeLoadingText {
    text-align: center;
    font-size: large;
}

.dataCollectionHomeCreateNewTaskCTARow {
    margin-top: 20px;
    cursor: pointer;
}

.dataCollectionHomeItem {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 5px;
}

.dataCollectionGraphButtonRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 10px;

    width: 8vw;

    cursor: pointer;
}

.dataCollectionItemTitle {
    text-align: start;

    flex: 3;

    cursor: pointer;
    font-size: large !important;

    color: black;
}

.dataCollectionHomeButtonsDiv {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
}



.dataCollectionButtonLabelParagraph {
    margin-top: 5px !important;

    font-size: xx-small !important;
    color: gray;
    text-align: center;
}

@media (prefers-color-scheme: dark) {
    .dataCollectionItemTitle {
        color: white;
    }
    .dataCollectionButtonLabelParagraph{
        color: white;
    }
    #dataCollection{
        color: var(--ion-color-secondary) !important;
    }
}

/* ######### DATA ANALYSIS ########## */

.dataAnalysisTaskItemDiv {
    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: 1px solid lightgray;
}

.dataAnalysisTaskTitleDiv {
    flex: 2;
}

.dataAnalysisTaskButtonsMainDiv {
    flex: 1;
}

.dataAnalysisHomeButtonIcon {
    font-size: x-large;
}

@media only screen and (max-width: 600px) {
    .dataAnalysisTaskItemDiv {
        flex-direction: column;
        align-items: unset;
    }

    .dataCollectionItemTitle {
        padding-top: 10px;
    }

    .dataAnalysisHomeButtonIcon {
        font-size: xx-large;
    }

    .dataCollectionGraphButtonRow {
        width: auto;
    }
}