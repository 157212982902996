.chatMainGrid {
    width: 100%;
    max-width: 500px;
    height: 99%;
    background: white;
}

.chatMessagesLoadingDiv {
    height: 99%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chatMessagesDiv {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.chatMessagesDiv::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.chatMessagesDiv {
    height: 99%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
}

.chatMessageContainer {
    display: flex;
    flex-direction: row;

    margin: 0;
    margin-top: 4px;

    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
}

.chatMessageSenderAndBubbleDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.chatMessageBubbleSenderName {
    flex: 1;

    margin: 0;
    margin-left: 5px;
    margin-bottom: 4px;
    padding: 0;
    font-size: small;
}

.chatMessageBubble {
    display: flex;
    border-radius: 20px;
    padding: 10px;
    overflow: hidden;
    position: relative;
    justify-content: center;
    align-items: center;
    background: var(--ion-color-light);
    min-width: 80px;
    max-width: 70%;
}

.chatMessageBubbleVideo {
    margin: 0;
    padding: 0;
    position: relative;
    max-width: 70%;
    max-height: 70vh;
}

.chatMessageBubbleImage {
    margin: 0;
    padding: 0;
    max-width: 550px;
}



/* MEDIA POPOVER */
.chatPopover {
    --width: 90%;
    --max-width: 700px;
    --height: 90vh;
    --max-height: 700px;
    --backdrop-opacity: 0.7;
    --box-shadow: none;
    --background: transparent;
}

.chatPopoverVideoDiv {
    display: flex;
    height: 99%;
    justify-content: center;
    align-items: center;
}

.chatPopoverVideo {
    justify-content: center;
    width: 100%;
    height: 100%;
}

.chatPopoverIcon {
    position: absolute;
    color: white;
    z-index: 1000;
    font-size: 20px;
}

.chatPopoverImageDiv {
    width: 100%;
    height: 99%;
}

.chatPopoverImage {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.chatMediaPopoverCloseIcon {
    position: absolute;
    right: 0;
    font-size: 20px;
    cursor: pointer;
    color: white;
}



/* FOOTER TOOLBAR */
.chatFooterToolbarDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
}