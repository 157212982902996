.autonomyTasksContentGrid {
    max-width: 550px;
}

.taskStepsDataCollectionSpinnerDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.taskStepsDataCollectionAddStepCTAParagraph {
    cursor: pointer;
    text-align: center;

    margin-top: 30px;
    margin-bottom: 25px;

    color: gray;
}

.taskStepsDataCollectionRow {
    padding-top: 10px;
    padding-bottom: 5px;
    justify-content: center;

    display: flex;
}

.pbListPopover{
    --height:50vh;
    --width: max-content;
    --backdrop-opacity: 0.8;
}

.pbListMainDiv{
    display: flex;
    flex-direction: column;
    max-width: max-content;
}

.pbListHeader{
    text-align: center;
    margin: 20px;
}

.pbListRow{
    justify-content: space-between;
}

.pbListItem{
    cursor: pointer;
    --background: inherit;
    font-size: 18px;
    margin: 10px;
}
.pbListDeleteIcon{
    align-self: center;
}

.modelingPlayerMedia{
    width: 100%;
    object-fit: scale-down;
    max-height: 300px;
}


@media only screen and (max-width: 600px) {
    .taskStepsDataCollectionRow {
        flex-direction: column !important;
    }
    .modelingPlayerMedia{
        max-width: 80%;
    }
    .taskStepsDataCollectionList{
        display: flex;
        flex-direction: column;
        align-items: center;
    }  
    .pbListPopover{
        --height:50vh;
        --width: 30vh;
    }
    .pbListHeader{
        text-align: left;
        font-size: 24px;
    }
    

}

.taskStepsDataCollectionTitleParagraph {
    /* display: flex;
    align-items: center; */
    margin-left: 15px;
    font-size: large !important;
}

.taskStepsDataCollectionBooleanCollectionDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.taskStepsDataCollectionPromptsButtonsDiv {
    display: flex;
    flex-direction: row;

    margin-bottom: 5px;
}

.taskStepsDataCollectionPromptButton {
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;

    text-transform: none;
}

#fixedButton {
    position: fixed;
    bottom: 10px;
    right: 10px; 
}

.taskStepsDataCollectionEndSessionButton {
    margin-top: 20px;
    text-transform: none;
}