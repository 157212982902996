.autonomySettingsContentGrid {
    max-width: 700px;
}

.autonomySettingsAutonomiesCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tasksListLoadingTasksDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.autonomySettingsHintText {
    margin-top: 5px;
    margin-bottom: 5px;
}

.tasksListTasksList {
    margin-top: 20px !important;
}

.autonomySettingsListItem {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 5px;
    
    
}
@media (prefers-color-scheme: dark) {
    .autonomySettingsListItem {
        --background: #242424;
    }
}

.autonomyGrid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-top: 10px;
    margin-bottom: 5px;

    padding: 0;
}

.tasksListTaskPreviewRow {
    width: calc(100vw / 10);
    max-width: 60px;
}

.tasksListPlayTaskRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tasksListEditTaskRow {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    cursor: pointer;
}

.tasksListButtonLabelParagraph {
    margin-top: 5px !important;

    font-size: xx-small !important;
    color: gray;
    text-align: center;

    cursor: pointer;
}

.tasksListAutonomyTitle {
    flex: 2;
    cursor: pointer;

    margin-right: 5px;
}

.tasksListAddTaskButtonAnimated {
    animation-name: addTaskButtonAnimation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes addTaskButtonAnimation {
	0% {
		transform: scale(1.0);
	}
	50% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1.0);
	}
}

@keyframes pulse {
    0% {transform: scale(1);}
    50% {transform: scale(1.07);}
    80% {transform: scale(1);}
}

.pulse {
    animation-name: pulse;
    animation-duration: 1.2s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }

.tasksListGuideHintDiv {
    color: black;
    border: black 15px;
    cursor: pointer;
    font-weight: normal;
    margin-left: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (prefers-color-scheme: dark) {
    .tasksListGuideHintDiv {
        color: white;
    }
    .tasksListButtonLabelParagraph{
        color: #ffffff;
    }
}

.tasksListGuideEmojiDiv {
    font-size: xx-large !important;
    margin-right: 10px !important;
}
