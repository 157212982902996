.updateEmailMainGrid {
    max-width: 500px;
    padding: 10px;
}

.updateEmailStepDiv {
    display: flex;
    flex-direction: column;
}

.updateEmailInsertPasswordParagraph {
    color: gray;
    margin-bottom: 5px;
}

.updateEmailWrongPassword {
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
    color: orange;
    font-size: small;
}

.updateEmailInputField {
    margin-top: 20px;
    margin-bottom: 5px;
    --padding-start: 15px;
    --padding-end: 15px;

    border: 1px solid gray;
    border-radius: 20px;
    overflow: hidden;
}

.updateEmailButtonDiv {
    margin-top: 20px;

    display: flex;
    flex-direction: row;
}

.updateEmailButton {
    flex: 1;
    text-transform: none;
}

.updateEmailSuccessStepDiv {
    height: 80vh;
    
    display: flex;
    flex-direction: column;
}

.updateEmailSuccessInnerDiv {
    flex: 1;
}

.updateEmailSuccessButtonDiv {
    display: flex;
    margin-top: 40px;
}

.updateEmailSuccessButton {
    flex: 1;
    text-transform: none;
}