.cookieAlertMainDiv {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000000AA;
    z-index: 10000;

    display: flex;
    justify-content: center;
    align-items: center; 
}

.cookieAlertDiv {
    background: white;
    color: black;

    width: 80%;
    max-width: 400px;
    

    border-radius: 10px;
    overflow: hidden;

    z-index: 10000;
}

.cookieAlertPolicyGrid {
    padding-left: 20px;
    padding-right: 20px;
}

.cookieAlertAcceptButton {
    flex: 1;
    text-transform: none;
}