.pbCreationPopover {
    --width: 90%;
    --max-width: 700px;
    --backdrop-opacity: 0.8;
}

.pbCreationMainDiv {
    margin: 20px;
}

.pbCreationPopoverTitle {
    margin-bottom: 20px;
    font-size: x-large; 
}