.notesCreationPopover {
    --width: 90%;
    --max-width: 50%;
    top: -25%;
}

@media only screen and (max-width: 480px) {
    .notesCreationPopover {
        --width: 90%;
        --max-width: 80%;
    }
}

.notesCreationPopoverTitle {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.notesCreationChildListItem {
    margin-bottom: 10px;
}

.notesCreationChildAvatarImg {
    width: 10vw;
    height: 10vw;
    max-width: 50px;
    max-height: 50px;

    margin-bottom: 10px;
}

.notesCreationGrid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
}

.addChildImgRow {
    margin-top: 10px;
    margin-bottom: 10px;
    flex: 1;
}

.addChildImg {
    width: calc(100vw / 2);
    height: auto;
}

.inputRow {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    --width: 300px;
}

.inputField {
    margin: 15px;
    border: solid 1px black;
    border-radius: 10px;
}

.buttonRow {
    margin-top: 10px;
}