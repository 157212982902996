.getPremiumPopover {
    --backdrop-opacity: 0.8;
}

.getPremiumPopoverMainDiv {
    padding: 10px;
}

.getPremiumPopoverTextParagraph {
    margin: 0;
    padding: 0;

    text-align: center;
}

.getPremiumPopoverButtonDiv {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.getPremiumPopoverButton {
    --background: orange;
}