.paymentCompletedMainGrid {
    max-width: 500px;
}

.paymentCompletedSuccessDiv {
    padding: 10px;
}

.paymentCompletedSuccessText {
    text-align: center;
    font-size: larger;
}

.paymentCompletedSuccessButtonsDiv {
    margin-top: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.paymentCompletedSuccessDiscoverButton {
    text-transform: none;
}