.swiper-pagination-bullet{
    background-color: white;
}

.swiper-pagination-bullet-active{
    background-color: white;
}

.onboardingSlideGrid {
    display: flex;
    flex-direction: column;
}

.onboardingSlideAnimationRow {
    display: flex;
    justify-content: center;
}

.onboardingSlideHeaderRow {
    flex: 1;
    display: flex;
    justify-content: center;
}

.onboardingSlideHeader {
    flex: 1;
    text-align: center;
    margin-top: 0;
}

.onboardingSlideBodyRow {
    flex: 1;
    display: flex;
    justify-content: center;
}

.onboardingSlideBodyParagraph {
    flex: 1;
    text-align: center;
    margin: 0;
    margin-bottom: 20px;
}

.onboardingSlideCTAButtonRow {
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
}

.onboardingSlideCTAButton {
    text-transform: none;
}

.purchaseSlidePicture {
    width: 10vh;
    height: 10vh;
    display: flex;
    align-items: center;
}