@keyframes wiggle {
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(3deg); }
   95% { transform: rotate(-3deg); }
  100% { transform: rotate(0deg); }
}

.activateNotificationsGrid {
    margin-top: 20px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;

    animation: wiggle 2s infinite;
}

.activateNotificationsGrid:hover {
    animation: none;
}

.activateNotificationsRow {
    flex: 1;
}

.activateNotificationsImage {
    width: 20vw;
    max-width: 80px;
    height: auto;

    margin-left: auto;
    margin-right: auto;
}

.activateNotificationCtaText {
    padding-left: 20px;
    padding-right: 20px;
    flex: 1;
}