.purchaseIosDiscountHeaderButton {
    font-size: x-large !important;
    margin-top: 6px !important;
}

.purchaseMainGrid {
    max-width: 700px;
    height: 100%;

    padding: 10px;
    padding-bottom: 0;
}

.purchaseMainDiv {
    height: 99%;
    display: flex;
    flex-direction: column;

    text-align: center;
}

.purchaseTitleDiv {
   flex: 1;
   
   display: flex;
   flex-direction: row;
   align-items: center;
}

.purchaseTitleInnerDiv {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.purchaseTitleParagraph {
    font-size: xx-large;
    margin-bottom: 10px;
}

.purchaseDiscountCouponDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: lightgray;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 2px;
    overflow: hidden;

    color: black;
}

.purchaseDiscountCouponName {
    padding: 0;
    margin: 0;
    margin-left: 5px;
}

.purchaseSlides {
    height: 85%;
}

.purchaseDiscountCouponHintOnMobileDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: var(--ion-color-success-shade);
    margin-top: 5px;
    padding: 5px;
    border-radius: 2px;
    overflow: hidden;

    color: white;
    cursor: pointer;
}

.purchaseBenefitsDiv {
    flex: 3;
}

.purchaseButtonsAndTermsDiv {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.purchaseButton {
    text-transform: none;
    /* --background: orange; */

    /* margin-bottom: 20px; */
}

.purchaseRestorePurchasesDiv {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: small;
    color: gray;
    cursor: pointer;
}

.purchaseTermsDiv {
    padding-left: 10px;
    padding-right: 10px;
    font-size: x-small;
    color: gray;
    text-align: center;

    margin-top: 10px;
    margin-bottom: 20px;
}

.purchaseTermsDiv > p > a {
    color: gray;
}